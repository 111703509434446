import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Image from "../components/image"
import Img from "gatsby-image"
import { useProfilePhoto } from "../custom-hooks/useProfilePhoto"
import { Link } from "gatsby"

const temporaryData = [
  "Natal: Kasih yang Tidak Berkesudahan",
  "Semakin Bersyukur, Bersaudara, dan Berbelarasa",
  "Dirgahayu Gereja Santa Maria Regina, Paroki Bintaro Jaya ke - 7",
  "SELAMAT NATAL",
  "Melayani dengan Gembira dan Tulus Hati",
  "Berbahagialah Orang yang Murah Hati",
  "Mari, Bersukacitalah!",
  "MALAM KUDUS?",
]

const BlogPage = () => {
  return (
    <React.Fragment>
      <SEO title="Blog, Renungan, Inspirasi Hidup" />
      <Layout>
        <div className="container my-5">
          <h1 className="display-4 text-center">
            Blog, Renungan, Inspirasi Hidup
          </h1>
          <div className="my-5" />
          <div className="row">
            {temporaryData.map(blog => {
              return (
                <div className="col-12 col-md-6 col-lg-3 my-3">
                  <Link
                    to="/blog-template"
                    className="text-decoration-none text-dark h-100 d-flex flex-column"
                  >
                    <Image />
                    <p className="text-muted m-0">Kategori</p>
                    <h4>{blog}</h4>
                    <p className="text-muted ">Sub Judul</p>
                    <div className="flex-grow-1" />
                    <div className="d-flex align-items-center">
                      <Img
                        fixed={useProfilePhoto()}
                        style={{ maxWidth: "24px", maxHeight: "24px" }}
                      />
                      <div>
                        <p className="m-0 ml-2 text-muted small">
                          Rm. Lucky · {new Date().toDateString()}
                        </p>
                      </div>
                    </div>
                  </Link>
                </div>
              )
            })}
          </div>
        </div>
      </Layout>
    </React.Fragment>
  )
}
export default BlogPage
